<template>
<div>
    <v-text-field
        :label="label"
        filled
        readonly
        @click="open"
        :rules="getRules('required')"
        class="required"
        v-model="nombreArchivo"
    >
        <template slot="prepend">
            <v-icon>mdi-camera</v-icon>
          </template>
    </v-text-field>
    <v-dialog
            v-if="dialog"
            v-model="dialog"
            scrollable 
            persistent 
            max-width="500px"
            transition="dialog-transition"
    >
        <v-card>
            <v-toolbar height="55px" color="grey lighten-3" flat>
                <div><v-icon class="mr-2">mdi-image</v-icon>{{label}}</div>
                <v-spacer></v-spacer>
                <v-btn color="error" icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="py-3">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-btn 
                            v-if="!upload && !camera"
                            color="success"
                            class="mr-1 mb-2 block"
                            @click="subirFoto"
                            style="width:250px"
                        >
                            <v-icon>mdi-image-plus</v-icon> Subir foto
                        </v-btn>
                        <v-btn 
                            v-if="!upload && !camera"
                            color="primary"
                            @click="camera = true"
                            style="width:250px"
                        >
                            <v-icon>mdi-camera</v-icon>Tomar foto
                        </v-btn>
                        <v-file-input
                            v-if="!archivo.original.url && upload"
                            v-model="imagen"
                            class="required px-1 mt-0"
                            :rules="getRules('required firma')"
                            accept="image/jpeg"
                            prepend-icon="mdi-camera"
                            label="Presiona aquí para subir una foto"
                            show-size
                            counter
                            filled
                            ref="refUploadField"
                            @change="onFileChange"
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip
                                    color="primary accent-4"
                                    dark
                                    label
                                    small
                                >
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                        <img 
                            v-if="archivo.seleccionado.url"
                            :src=" archivo.seleccionado.url"
                            width="300px" 
                        />
                        <template v-if="tipo==='firma' && archivo.original.url && !archivo.seleccionado.url">
                            <v-alert type="info" :value="true" outlined>
                                Seleccione la mejor imagen para la firma
                            </v-alert>
                            <v-card style="width:14cm!important;height:auto!important" class="mb-2 card-firma" @click="seleccionar(archivo.procesado)">
                                <v-card-text class="pb-0 font-weight-bold">
                                    Imagen procesada
                                </v-card-text>
                                <v-card-text class="py-1">
                                    <img v-if="archivo.procesado.url" :src="archivo.procesado.url" width="200px" />
                                </v-card-text>
                            </v-card>
                            <v-card style="width:14cm!important;height:auto!important" class="mb-2 card-firma" @click="seleccionar(archivo.original)">
                                <v-card-text class="pb-0 font-weight-bold">
                                    Imagen original
                                </v-card-text>
                                <v-card-text class="py-1">
                                    <img v-if="archivo.original.url" :src="archivo.original.url" width="85%" height="auto" />
                                </v-card-text>
                            </v-card>
                            
                        </template>
                        <Camera 
                            v-if="camera"
                            @capture="actualizarFotoCamara"
                            @error="camera = false"
                        />
                    </v-col>
                    <v-col cols="12" v-if="archivo.seleccionado.url" class="py-0">
                        <v-alert type="info" outlined dense >
                            <span class="caption" v-html="reglas[tipo]['titulo']"></span>
                        </v-alert>
                        <v-form  ref="formFoto" v-model="valid" lazy-validation>     
                            <v-checkbox
                                v-model="regla.valido"
                                :rules="[v => !!v || 'Debes aceptar para continuar!']"
                                required
                                v-for="(regla,index) in reglas[tipo]['condiciones']"
                                :key="index"
                            >
                                <template v-slot:label>
                                    <div v-html="regla.label"></div>
                                </template>
                            </v-checkbox>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="upload && archivo.seleccionado.url" color="primary" text @click="reset(true)">subir otra foto</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="archivo.seleccionado.url" color="success" @click="continuar">Continuar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <img v-if="urlFinal" :src="urlFinal"  :width="tipo==='foto'?'180px':'300px'"  />
</div>
</template>

<script>
import mixin_rules from "@/mixins/rules"

const reglasDefault = {
            foto:{
                titulo:'La fotografía debe <b>visualizarse claramente, sin lentes, ni sombrero u otro accesorio.</b>',
                condiciones:[{ label:'Declaro que mi fotografía está en el formato establecido',valido:false }]
            },
            firma:{
                titulo:'La firma debe ser de <b>color azul, fondo blanco y nítida.</b>',
                condiciones:[{label:'Declaro que mi firma está en formato establecido',valido:false }]
            }
        }
const archivoDefault = {
        original:{},
        procesado:{},
        seleccionado:{}
}
export default {
    props:{
        label:{
            type:String,
            default:'Fotografía (3x3)'
        },
        tipo:{
            type:String,
            default:'foto'
        }
    },
    mixins:[mixin_rules],
    components:{
        Camera:()=>import("@/components/Camera")
    },
    data(){
        return {
            dialog:false,
            reglas:{...reglasDefault},
            valid:true,
            urlFinal:null,
            upload:true,
            camera:false,
            nombreArchivo:null,
            archivo:{...archivoDefault},
            imagen:null
        }
    },
    mounted(){
        if(this.tipo === 'firma'){
            this.upload=null
            this.camera=null
        }
    },  
    methods:{
        async open(){
            this.reset(this.tipo !== 'firma')
            this.dialog = true
            //await this.setupCamera()            
        },
        close(){
            this.dialog = false
            this.reset()
        },
        reset(upload=false){
            this.imagen=null
            this.archivo = JSON.parse(JSON.stringify(archivoDefault)),
            //this.urlFinal = null,
            this.reglas = JSON.parse(JSON.stringify(reglasDefault))
            if(upload){
                setTimeout(()=>{
                    this.$refs.refUploadField.$el.querySelector('input').click()
                },20)
            }
            if(this.tipo === 'firma'){
                this.upload=null
                this.camera=null
            }else{
                this.upload=true
                this.camera=false
            }
        },
        subirFoto(){
            this.upload = true
            setTimeout(()=>{
                    this.$refs.refUploadField.$el.querySelector('input').click()
                },20)
        },
        onFileChange(files){
            try{
                if(!files){
                    return this.archivo= JSON.parse(JSON.stringify(archivoDefault))
                }
                const file = files;
                this.archivo.original = {
                    url:URL.createObjectURL(file),
                    archivo:file
                };
                if(this.tipo==='firma'){
                    this.$store.commit('setMessageOverlay', 'Optimizando firma, espere porfavor')
                    this.$store.commit('setOverlay', true);
                    const formData = new FormData()
                    formData.append('firma',file)
                    this.$http.post(`${this.$apiUrl}abogado-ciudadano/optimizar-firma`,
                    formData, {
                        headers: {"Content-Type": "multipart/form-data"} ,
                        responseType: 'blob' 
                    }).then(response=>{
                        const headers = response.headers;
                        const filename = headers['content-disposition'] && headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
                        const contentType = headers['content-type'];
                        const outside = URL.createObjectURL(response.data)
                        this.$store.commit('setOverlay', false);
                        this.archivo.procesado = {
                            url:outside,
                            archivo:new File([response.data], filename, { type: contentType })
                        }
                    }).catch(error=>{
                        console.log('------------------------------------');
                        console.log(error);
                        console.log('------------------------------------');
                        this.$store.commit('setOverlay', false);
                    })
                }else{
                    this.archivo.seleccionado = {
                        url:URL.createObjectURL(file),
                        archivo:file
                    };
                }
            }catch(error){
                console.log('------------------------------------');
                console.log(error);
                console.log('------------------------------------');
            }
        },
        continuar(){
            if(this.$refs.formFoto.validate()){
                this.urlFinal = this.archivo.seleccionado.url
                this.nombreArchivo = this.archivo.seleccionado.archivo.name
                this.$emit('change',this.archivo.seleccionado.archivo)
                this.reset()
                this.dialog=false
            }
        },
        actualizarFotoCamara(data){
            this.archivo.original = data.original
            this.archivo.procesado = data.procesado
        },
        seleccionar(data){
            this.archivo.seleccionado = data
        }
    }
    
}
</script>
<style>
.card-firma:hover{
    background-color: #B2DFDB;
    color: #fff !important;
}
</style>